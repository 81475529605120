<template>
  <b-row class="mt-3">
    <b-col>
      <b-tabs
        v-model="tabIndex"
        :no-nav-style="true"
        nav-class="test-api-tabs"
        content-class="test-api-content custom-scroll color-black"
      >
        <component
          v-for="(tab, tIndex) in tabs"
          :key="tIndex"
          :is="tab"
          :apiResponse="apiResponse"
        ></component>
      </b-tabs>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: 'TestAPITabs',
  props: ['apiResponse', 'tabs'],
  data () {
    return {
      tabIndex: 0
    }
  },
  mounted () {
    setTimeout(() => {
      this.tabIndex = 0
    }, 500)
  },
  components: {
    APIResponse: () => import('./APIResponse.vue'),
    Exceptions: () => import('./Exceptions.vue'),
    Notifications: () => import('./Notifications.vue'),
    Output: () => import('./Output.vue')
  }
}
</script>
<style lang="scss" scoped>
::v-deep .test-api-content {
  background: #f1f1f1;
  height: 404px;
  overflow: auto;
  border: 1px solid #c6c6c6;
  padding: 1rem 0.1rem 0 0.1rem;
  div.tab-pane {
    padding: 0 !important;
  }
}
::v-deep .tabs {
  .nav-link {
    padding: 0.5rem 0;
    margin-right: 1rem;
    margin-left: 1rem;
    color: #000;
  }
  pre {
    white-space: pre-wrap;
    word-break: break-all;
  }
  .notifications-list {
    border-bottom: 1px solid #c6c6c6;
  }
  .notifications-list:last-child {
    border-bottom: 0px solid #c6c6c6;
  }
}
</style>
